import React, { createContext, useContext, useState, useEffect } from "react";
import { Login } from "../services/Auth";
import { notify } from "../utils/notify";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for token in local storage on component mount
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (email, password) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
  
      // Commenting out the actual Login API call
      // const res = await Login({ username: email, password });
  
      // Hardcoded credentials
      const hardcodedEmail = "atlas";
      const hardcodedPassword = "August@2024";
  
      if (email === hardcodedEmail && password === hardcodedPassword) {
        setIsAuthenticated(true);
        localStorage.setItem("authToken", "hardcoded-auth-token");
        return true;
      } else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      notify("error", error.message);
      return false;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("authToken");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
