import axios from "axios";

// Base URL for the API
const baseUrl = process.env.REACT_APP_BASE_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the Authorization token
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem("authToken");

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add meta information to track request start time
    config.meta = config.meta || {};
    config.meta.requestStartedAt = new Date().getTime();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
// Response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // If there's no response (network error)
    if (!error.response) {
      return Promise.reject({
        statusCode: 500,
        message: "A network error occurred. Please try again later.",
      });
    }

    // Check if the error is a 401 Unauthorized
    if (error.response.status === 401) {
      // Redirect to login page
      window.location.href = "/login";
      // Remove token from localStorage
      localStorage.removeItem("authToken");
    }

    // Reject with the response data if it exists, or provide a default message
    return Promise.reject(
      error.response.data || {
        statusCode: error.response.status,
        message: error.message || "An error occurred. Please try again later.",
      }
    );
  }
);

export default axiosInstance;
