import React, { useState } from "react";
import {
  Button,
  CardBody,
  Switch,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";

export const ProcessTables = ({
  addRow,
  jsonData,
  editIndex,
  activeTab,
  setActiveTab,
  setEditIndex,
  selectedRows,
  toggleRowSelection,
  handleInputChange,
  isEditingEnabled,
  setIsEditingEnabled,
  deleteSelectedRows,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [editFieldValue, setEditFieldValue] = useState("");

  const handleFieldChange = (e) => {
    setEditFieldValue(e.target.value);
  };

  const handleFieldEdit = (key, value) => {
    if (!isEditingEnabled) return;
    setEditFieldKey(key);
    setEditFieldValue(value !== null && value !== undefined ? value : "");
  };

  const saveFieldEdit = (key) => {
    jsonData[key] = editFieldValue;
    setEditFieldKey(null);
  };

  return (
    <>
      <CardBody>
        <Typography variant="h6" color="black">
          Invoice Data :-
        </Typography>
        <div className="my-4">
          <Switch
            checked={isEditingEnabled}
            onChange={() => setIsEditingEnabled(!isEditingEnabled)}
            label="Enable / Disable Editing Zoho Data"
            className="mb-4"
          />
        </div>
        {jsonData.line_items["mapped"]?.length > 0 && (
          <>
            <div className="mt-5 mb-5" style={{ display: "flex",gap:"1px" }}>
         
          <div style={{ flex: "1", maxWidth: "50%"}}>
            {Object.entries(jsonData)
              .filter(
                ([key]) =>
                  !["success", "line_items", "message", "extracted_line_items_total", "amounts_matched"].includes(key)
              )
              .map(([key, value]) => (
                <div key={key} style={{ marginBottom: "8px",width:"auto"}}>
                  <strong
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleFieldEdit(key, value)}
                  >
                    {key.replace(/_/g, " ").toUpperCase()}:
                  </strong>{" "}
                  {editFieldKey === key ? (
                    <input
                      type="text"
                      value={editFieldValue}
                      onChange={(e) => handleFieldChange(e)}
                      onBlur={() => saveFieldEdit(key)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={() => handleFieldEdit(key, value)}
                      style={{
                        cursor: isEditingEnabled
                          ? "pointer"
                          : "not-allowed",
                        textDecoration:
                          value === "" || value === null
                            ? "underline"
                            : "none",
                        outline:
                          editFieldKey === key
                            ? "2px solid #1E201E"
                            : "none",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.outline = isEditingEnabled
                          ? "2px solid #1E201E"
                          : "none")
                      }
                      onMouseOut={(e) => (e.target.style.outline = "none")}
                    >
                      {value === "" || value === null
                        ? "<Click to add value>"
                        : value}
                    </span>
                  )}
                </div>
              ))}
          </div>

          <div
            style={{
              flex: "0.5",
            }}
          >
            {Object.entries(jsonData)
              .filter(
                ([key]) =>
                  ["extracted_line_items_total", "amounts_matched"].includes(key)
              )
              .map(([key, value]) => (
                <div key={key} style={{ marginBottom: "8px" }}>
                  <strong
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleFieldEdit(key, value)}
                  >
                    {key.replace(/_/g, " ").toUpperCase()}:
                  </strong>{" "}
                  {editFieldKey === key ? (
                    <input
                      type="text"
                      value={editFieldValue}
                      onChange={(e) => handleFieldChange(e)}
                      onBlur={() => saveFieldEdit(key)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={() => handleFieldEdit(key, value)}
                      style={{
                        cursor: isEditingEnabled
                          ? "pointer"
                          : "not-allowed",
                        textDecoration:
                          value === "" || value === null
                            ? "underline"
                            : "none",
                        outline:
                          editFieldKey === key
                            ? "2px solid #1E201E"
                            : "none",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.outline = isEditingEnabled
                          ? "2px solid #1E201E"
                          : "none")
                      }
                      onMouseOut={(e) => (e.target.style.outline = "none")}
                    >
                      {value === "" || value === null
                        ? "<Click to add value>"
                        : value}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
          </>
        )}

        <div className="flex justify-start mb-10 mt-5">
          <Button
            onClick={addRow}
            className="mr-2"
            disabled={!isEditingEnabled || activeTab === "raw"}
          >
            Add Row
          </Button>
          <Button
            onClick={deleteSelectedRows}
            disabled={!isEditingEnabled || activeTab === "raw"}
          >
            Delete Selected Rows
          </Button>
        </div>

        <Tabs value={activeTab} key={activeTab}>
          <TabsHeader>
            <Tab value="raw" onClick={() => setActiveTab("raw")}>
              Extracted Data
            </Tab>
            <Tab value="mapped" onClick={() => setActiveTab("mapped")}>
              Zoho Data
            </Tab>
          </TabsHeader>

          {/* TabsBody renders the content based on the active tab */}
          <TabsBody>
            <TabPanel value="mapped">
              <CardBody>
                <Typography variant="h6" color="black" className=" mb-4">
                  Invoice Data - Zoho Data
                </Typography>
                <RenderTable
                  activeTab={activeTab}
                  selectedRows={selectedRows}
                  toggleRowSelection={toggleRowSelection}
                  data={jsonData.line_items["mapped"]}
                  isEditable={true}
                  isEditingEnabled={isEditingEnabled}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  handleInputChange={handleInputChange}
                />
              </CardBody>
            </TabPanel>

            <TabPanel value="raw">
              <CardBody>
                <Typography variant="h6" color="black" className=" mb-4">
                  Invoice Data - Extracted Data
                </Typography>
                <RenderTable
                  activeTab={activeTab}
                  selectedRows={selectedRows}
                  toggleRowSelection={toggleRowSelection}
                  data={jsonData.line_items["raw"]}
                  isEditable={false}
                  isEditingEnabled={isEditingEnabled}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  handleInputChange={handleInputChange}
                />
              </CardBody>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </CardBody>
    </>
  );
};

const RenderTable = ({
  activeTab,
  selectedRows,
  toggleRowSelection,
  data,
  isEditable = false,
  isEditingEnabled,
  setEditIndex,
  editIndex,
  handleInputChange,
}) => {
  return (
    <div className="overflow-x-auto overflow-y-auto max-h-96">
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            {activeTab === "mapped" && (
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-100 text-left sticky top-0 bg-white">
                Select
              </th>
            )}
            {Object.keys(data[0])?.map((header) => (
              <th
                key={header}
                className="py-1 px-2 border-b border-gray-200 bg-gray-100 text-left sticky top-0 bg-white"
              >
                {header.charAt(0).toUpperCase() + header.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {activeTab === "mapped" && (
                <td className="py-1 px-2 border-b border-gray-200 text-center">
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(rowIndex)}
                    onChange={() => toggleRowSelection(rowIndex)}
                    disabled={!isEditingEnabled}
                  />
                </td>
              )}
              {Object.keys(item)?.map((field, colIndex) => (
                <td
                  key={field}
                  className="py-1 px-2 border-b border-gray-200"
                  onClick={(e) => {
                    if (isEditingEnabled && isEditable) {
                      setEditIndex({ row: rowIndex, col: colIndex });
                      e.currentTarget.style.outline = "none";
                    }
                  }}
                  style={{
                    cursor: isEditingEnabled ? "pointer" : "not-allowed",
                    outline: "none",
                  }}
                  onMouseEnter={(e) => {
                    if (
                      !(
                        editIndex.row === rowIndex && editIndex.col === colIndex
                      )
                    ) {
                      e.currentTarget.style.outline = isEditingEnabled
                        ? "2px solid #1E201E"
                        : "none";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (
                      !(
                        editIndex.row === rowIndex && editIndex.col === colIndex
                      )
                    ) {
                      e.currentTarget.style.outline = "none";
                    }
                  }}
                >
                  {editIndex.row === rowIndex &&
                  editIndex.col === colIndex &&
                  isEditingEnabled &&
                  isEditable ? (
                    <input
                      type="text"
                      value={item[field] || ""}
                      onChange={(e) =>
                        handleInputChange(rowIndex, field, e.target.value)
                      }
                      className="border-2 border-gray-200 p-1"
                      style={{
                        width: `${Math.max(
                          (item[field] || "").length * 8,
                          100
                        )}px`,
                      }}
                      autoFocus
                      //commented due to rerender edit issue
                      // onBlur={() => setEditIndex({ row: null, col: null })}
                    />
                  ) : (
                    item[field] || ""
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
